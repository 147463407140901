<template>
  <div id="camera">
    <b-row>
      <b-col cols="12">
        <b-form-group>
          <label for="camera-select">Definir webcam</label>
          <multiselect
            :value="cameraSelected"
            id="camera-select"
            :options="cameras"
            @select="selectCamera"
            label="label"
            :option-height="40"
            :showLabels="false"
            :searchable="true"
            :showNoResults="true"
            placeholder="Selecionar"
            class="with-border"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="noOptions"> Nenhuma opção </template>
          </multiselect>
        </b-form-group>
      </b-col>
    </b-row>
    <transition mode="out-in">
      <div id="web-camera-container">
        <div
          v-show="(isCameraOpen && isLoading) || error"
          class="camera-loading"
        >
          <b-skeleton-img />
        </div>

        <div
          v-show="!isLoading && isCameraOpen && !error"
          class="camera-box"
          :class="{ flash: isShotPhoto }"
        >
          <div class="camera-shutter" :class="{ flash: isShotPhoto }" />
          <video v-show="!isPhotoTaken" ref="camera" class="web-cam" autoplay />
          <canvas
            v-show="isPhotoTaken"
            id="photoTaken"
            class="web-cam"
            ref="canvas"
            :width="450"
            :height="337.5"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ChevronDown from '@/assets/icons/chevron-down.svg'
export default {
  name: 'Camera',
  components: {
    ChevronDown
  },
  data() {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      cameras: [],
      cameraId: null,
      constrainsts: {
        video: {}
      },
      error: false
    }
  },
  computed: {
    cameraSelected() {
      return this.cameras.find(camera => camera.id === this.cameraId)
    }
  },
  methods: {
    async selectCamera(option) {
      this.cameraId = option.id
      this.isCameraOpen = true
      this.constrainsts.video.deviceId = { exact: option.id }
      this.isPhotoTaken = false
      this.isShotPhoto = false
      this.stopCameraStream()
      await this.createCameraElement()
    },
    clearCamera() {
      this.isCameraOpen = false
      this.isPhotoTaken = false
      this.isShotPhoto = false
      this.stopCameraStream()
    },
    async toggleCamera() {
      if (this.isCameraOpen) {
        this.clearCamera()
      } else {
        this.isCameraOpen = true
        await this.createCameraElement()
        await this.getDevices()
      }
    },
    async createCameraElement() {
      this.isLoading = true
      try {
        const stream = await navigator.mediaDevices.getUserMedia(
          this.constrainsts
        )
        this.$refs.camera.srcObject = stream
        delete this.constrainsts.video.deviceId
        this.error = false
      } catch (error) {
        this.$toast.warning('Não foi possível acessar a câmera')
        this.error = true
      } finally {
        this.isLoading = false
      }
    },
    stopCameraStream() {
      if (this.$refs.camera.srcObject) {
        try {
          const tracks = this.$refs.camera.srcObject.getTracks()
          tracks.forEach(track => {
            track.stop()
          })
        } catch (error) {
          this.$toast.warning('Não foi possível parar a câmera')
        }
      }
    },
    async takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true
        setTimeout(() => {
          this.isShotPhoto = false
        }, 50)
      }
      this.isPhotoTaken = !this.isPhotoTaken
      const context = this.$refs.canvas.getContext('2d')
      context.drawImage(this.$refs.camera, 0, 0, 450, 337.5)
      await this.createFile()
    },
    getCanvasBlob(canvas) {
      return new Promise(function (resolve, reject) {
        canvas.toBlob(function (blob) {
          resolve(blob)
        })
      })
    },
    async createFile() {
      const canvasBlob = await this.getCanvasBlob(this.$refs.canvas)
      const file = new File([canvasBlob], 'profile-picture.png', {
        type: 'image/png'
      })
      this.$emit('changeFile', file)
    },
    async getDevices() {
      const cameras = []
      const devices = await navigator.mediaDevices.enumerateDevices()
      let i = 0
      for (const device of devices) {
        if (device.kind === 'videoinput') {
          cameras.push({
            id: device.deviceId,
            label: device.label || 'camera ' + (i + 1)
          })
        }
        i++
      }
      this.cameraId = cameras[0].id
      this.cameras = cameras
    }
  }
}
</script>

<style lang="scss">
#camera {
  .row {
    margin-top: 2% !important;
    width: 97%;
    margin: 0 auto;
  }
  #web-camera-container {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;
    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 1s;
    }
    .fade-enter,
    .fade-leave-to {
      opacity: 0;
    }
    .camera-box {
      max-height: 500px;
      .camera-shutter {
        opacity: 0;
        &.flash {
          opacity: 1;
        }
      }
      .web-cam {
        width: 470px;
        border-radius: 8px;
        height: auto;
      }
    }
    .camera-loading {
      margin-top: 1%;
      .b-skeleton {
        width: 470px;
        border-radius: 8px;
      }
    }
    @keyframes preload {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.4;
      }
      100% {
        opacity: 1;
      }
    }
  }
}
</style>